@import url('hover.css');

body {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 300 !important;
  background: #ffffff;
  overflow-x: hidden;
  color: #323232 !important;
 
}

#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

}

.link{
  text-decoration: none !important;
}

.container{
  max-width: 80%;
  margin: 0 auto;
  padding: 0;
}

.main{
  padding: 3em ;
  /* margin: 3em; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

}

h1{
  padding: .5em 0;
}


.WerkbonTable{
  width: 40%;
}

.WerkbonTableMobile{
 width: 95%; 
}
.createWerkbonWrapper{

  width: 40%;

}

.editWerkbonWrapper{
  margin: 0 auto;
  width: 40%;
}
 
.Werkbonnen{
  max-height: 500px;
  
  overflow: scroll;
  overflow-x: hidden;
}

.werkbonTableRow { 
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top:2px solid  #e1e1e1 ;
}



.werkbonFirstTableRow{
  padding: 1em;

  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: #323232 1px solid;
}

.coll{
  width:25%;
  justify-content: space-around;
  text-decoration: none !important;
  margin: .25em;
}

.mobileColl{
  width:40%;
  justify-content: space-around;
  text-decoration: none !important;
  margin: .25em;
}


.ThumbImage{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  width: 100%;
}

.title{
  
  text-align: left;
  margin: 1em;
}


.tweet:hover {
  transform: scale();
}

.likes {

  display: flex;
  flex-direction: row;

}

.loading {
  color: #fff;
}

.button {
  padding: 1em;
  margin: 2em;
  outline: none;
}

.unlink{
  color: #323232;
  text-decoration: none !important;
}

.unlink:visited{
  text-decoration: none;

}

.image {
  max-width: 100%;
  border-radius: 5px;
}

.no-movies{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3em;
  height: 60%;
}

/* navbar  */

.navbar {

  background-color: #f4f4f5;
  min-height: 15%;
  display: flex;
  align-items: center;
  /* box-shadow: 0px 10px 58px 9px rgba(212,212,212,1); */

}

.werkbonNavMobile{
  display: none;
}

.logo{
  margin: 2em;
}
.menuItems{
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

}
.nav-p {
  margin: 1em;
  font-size: 25px;
  font-weight: 300;
  color: #323232;
}
.nav-p:hover{

  color: #e30613;

}

.footer{
  background-image: url(https://i.ibb.co/hMQxTSR/APK-background.png);

  background-repeat: repeat-x;

}


input {
  padding: 1em;
  margin: 1em;
  max-height: 20px;
}

.button {
  padding: 1em;
  width: 100px;
  border: none;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* detail page */



div.header.large {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  
}

.backdropImg{
  height: 100vh;
  object-fit: cover;
  filter: opacity(1) contrast(150%) grayscale(30%) brightness(25%);
}

.backdropContent{
  display: flex;
  flex-direction: row;
  position: absolute;
  
}

.backdropPoster{
  margin: 1em;
  -webkit-box-shadow: 0px 10px 29px 3px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 10px 29px 3px rgba(0,0,0,0.75);
box-shadow: 0px 10px 29px 3px rgba(0,0,0,0.75);
}

.backdropDetails{
  color: #fff;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 1em ;
  padding: 3em 1em 4em 1em;
}

/* aside info */

.aside{
  margin: 1em 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-weight: 150%;
  font-size: 120%;
  padding: 1em;
  
 
 }
.signatureImg{
  width:400px;
}
 .flex-row{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
 }

 .no-spacing{
  justify-content: center;
  align-items: center;

}
.infodiv{

  margin: 1em;
  width: 400px;
  border-bottom: 1px solid #e1e1e1;

}

.infodiv :nth-child(2){
 margin: 1em;
  
}
/* .infodiv{
  border-bottom: 3px solid #e2222e;
} */

.asideBody{
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}
/* more details */
.moreDetails{
  padding: 2em;
  margin:0 auto;
  width: 80%;
  
}

/* similar row */

.similarThumbnail{
  border-radius: 10px;
  background-color: #eee;
  margin: .5em;
  width: 200px;
  height: 95%;

}

.similarThumbImg{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  width: 100%;
}

.similarThumbTitle{
  margin: 1em;
}


.reviewsAndCast{
  margin-top: 5em;
  display: flex;
  border-top: 2px solid #eee ;
}

.reviews{
  width: 60%;
}

/* cast list  */
.castdiv{
  border-left: 1px solid #eee;
  padding: 1em;
  width: 40%;

}

.castList{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.castThumbnail{
  border-radius: 10px;
  background-color: #eee;
  padding: 1em;
  margin: .5em;
  width: 200px;
  height: 350px;
}

.castThumbImg{
  border-radius: 5px;
  width: 100%;
}

.filler{
  height: 300px;
}

/* EDIT ICON IN DETAIL PAGE VARIABLE */

.noEdit>a> img{
  filter: opacity(20%);

}

.noEdit>a{
  pointer-events: none;
}
[data-popup] {

  position: relative;

}
[data-popup]:before, [data-popup]:after {
  opacity: 0;
  transform: translate(-50%, 0);
  transition: 0.15s ease opacity, 0.15s ease transform;
  will-change: opacity transform;
  content: "";
}
[data-popup]:hover:before, [data-popup]:hover:after {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -10px);
  opacity: 1;
}
[data-popup]:hover:before {
  bottom: 100%;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  color: white;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  content: attr(data-popup);
  white-space: nowrap;
}
[data-popup]:hover:after {
  top: 0;
  height: 0;
  width: 0;
  border: solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
  border-width: 5px;
  content: "";
  
}
/* ADMIN ICONS + HIDDEN DIV FOR ADMIN */

.adminIcon{
  margin: 0.5em;
}

.adminIcon:hover{
  cursor: pointer;  
}

.adminIcon>img {
 margin: .5em;
}

.adminPanel{
  display: flex;
align-self: flex-end;
  flex-direction: row;
}



/* INPUT FIELDS NEW WERKBON */


.flexColumn{
  padding: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.normalInput{
  outline: none;
  border: 1px #e1e1e1 solid;
  border-radius: 10px;
  margin: 1em 0;
  width: 100% !important;
}



.SaveButton{
  cursor: pointer;
  margin: 1em;
  color: #fff;
  padding: 1em 2em;
  border-radius: 20px;
  background-color: #e30613;
  border: none;
  float: right;
}

.ClearButton{
  cursor: pointer;
  margin: 1em;
  color: #fff;
  padding: 1em 2em;
  border-radius: 20px;
  background-color: #e30613;
  border: none;
  float: right;
  width: 64px;
  font-size: 8px;
}

.longtextInput{
  font-family: 'Open Sans', sans-serif !important;

  outline: none;
  resize: none;
  border: 1px #e1e1e1 solid;
  margin: 1em 0;
  padding: 1em;
  border-radius: 10px;
  height: 150px ;
}

.editIcon{
  margin: 0 90%;
}

/* CUSTOM SCROLLBAR */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
  background: #999; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb; 
}


/* Radio button cool animation B) */


.toggle-container {
  display: inline-block;
  width: 42px;
  height: 22px;
  border: 1px solid #bfbebe;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  background-color: #f5f2f2;
  transition: border-color 300ms;
}
.toggle-container .real-checkbox {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.toggle-container .real-checkbox + .toggle-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  transition: all 300ms;
}
.toggle-container .real-checkbox + .toggle-button::before {
  content: '';
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  transition: all 300ms ease-in-out;
}
.toggle-container .real-checkbox:checked + .toggle-button {
  background-color: #84B12D;
}
.toggle-container .real-checkbox:checked + .toggle-button::before {
  margin-left: 20px;
}

.werkbonCheckbox{
  justify-content: left;

}

.werkbonCheckbox p {
  padding: 1em;
}

/* LOGIN PAGE CSS */

.LoginPageMain{
margin: 3em auto;
width: 80%;
display: flex;
align-items: center;
justify-content: center;

}
.GoogleAuth{
  padding: 2em;
  border: 1px solid #e1e1e1 ;
  border-radius: 10px;
  width: 450px;
  height: 500;
  text-align: center;
  
}
.googleButton{
margin: 4em auto;

}
.google{
  font-weight: normal;
}

/* WERKBONNEN PAGE CSS  */
.WerkbonnenPageMain{
  margin: 1em auto;
width: 95%;
display: flex;
align-items: center;
justify-content: center;

}

/* MEDIA QUERRIES LAST -=-=-=-=- NO NORMAL CSS BELOW THIS */
/* MEDIA QUERY 1230 phone and tablet */
@media only screen and (max-width: 1230px) and (min-width: 500px){

  .main{
    padding: 1em 2em ;
    display: flex;
    flex-direction: column;
  }
  .navbar{
    height: 100px;
    justify-content: space-between
  }
  .createWerkbonWrapper{
    width: 100%;
  }

  .editWerkbonWrapper{
    width: 100%;
    padding: 1em;
  }

  .WerkbonTable{
    display: none;
  }

  .flex-row{
    flex-direction: column;
  }

  .aside{
    padding: 0;
    margin: 0;
  }
  .infodiv, .flex-row, .aside, .asideBody{
    width:100%;
    
  }

  .infodiv{
    margin: 0;
    padding-top: 1em;
  }

  .asideBody{
    padding: 1em;
  }
  .menuItems{
    
    padding-right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items:flex-start ;
  }

  .nav-p:hover{
    color: #e30613 !important; 

  }
  .logo{
    height: 30px;
  }
  .flexColumn{
    padding: 0;
    margin: 0;
  }
  .footer{
    background-position: right;
  }

  .sigCanvas{
    height: 300px;
  }
  
  .werkbonNavMobile{
    display: inline;
  }
  .mobileDisappearer{
    display: none;
  }
  .mobileWerkbonCheckbox {
    display: flex;
    float: right;
  }
  .mobileWerkbonCheckbox > span{
    padding: 1em;
  }

  .editIcon{
    margin: 0 auto;
  }
}

@media all and (max-width: 499px) {

  .main{
    padding: 1em 2em ;
    display: flex;
    flex-direction: column;
  }
  .navbar{
    height: 100px;
    justify-content: space-between
  }
  .createWerkbonWrapper{
    width: 100%;
  }

  .editWerkbonWrapper{
    width: 100%;
    padding: 1em;
  }
  .WerkbonTable{
    display: none;
  }

  .flex-row{
    flex-direction: column;
  }


  .aside{
    padding: 0;
    margin: 0;
  }
  .infodiv, .flex-row, .aside, .asideBody{
    width:100%;
    
  }
  .flexColumn{
    padding: 0;
    margin: 0;

  }

  .infodiv{
    margin: 0;
    padding-top: 1em;
  }

  .asideBody{
    padding: 1em;
  }

  .menuItems{
    
    padding-right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items:flex-start ;
  }

  .nav-p:hover{
    color: #e30613 !important; 

  }
  .logo{
    height: 30px;
  }

  .footer{
    background-position: right;
  }
  .GoogleAuth{
    padding: .5em;
    border: none ;
    border-radius: none;
    width: 100%;
    height: 100%;
    text-align: center;
    
  }

  .LoginPageMain{
    margin: 1em auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    }

    h1{
      font-size: 130%;
    }

    .sigCanvas{
      height: 300px;
    }
    .werkbonNavMobile{
      display: inline;
    }
    
  .mobileDisappearer{
    display: none;
  }

  .mobileWerkbonCheckbox {
    display: flex;
    float: right;
  }
  .mobileWerkbonCheckbox > span{
    padding: 1em;
  }

  .editIcon{
    margin: 0 auto;
  }

}