table.user-mgmt-table {
    border: 1px solid #D8D8D8;
    width: 50%;
    max-height: 90vh;
    text-align: left;
    border-collapse: collapse;
  }
  table.user-mgmt-table td, table.user-mgmt-table th {
    border: 1px solid #FFFFFF;
    padding: 3px 2px;
  }
  table.user-mgmt-table tbody td {
    font-size: 13px;
  }
  table.user-mgmt-table tr:nth-child(even) {
    background: #F5F5F5;
  }
  table.user-mgmt-table thead {
    background: #E30613;
  }
  table.user-mgmt-table thead th {
    font-size: 13px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
  }
  table.user-mgmt-table tfoot td {
    font-size: 14px;
  }
  table.user-mgmt-table tfoot .links {
    text-align: right;
  }
  table.user-mgmt-table tfoot .links a{
    display: inline-block;
    background: #1C6EA4;
    color: #FFFFFF;
    padding: 2px 8px;
    border-radius: 5px;
  }

  #user-mgmt-table-wrapper {
    height: 55vh;
    overflow-y: scroll;
    margin: 0px 128px;
  }